import { FunctionComponent, ReactNode } from 'react';

import Breadcrumbs from '@belong/ui-components/components/navigation/Breadcrumbs';
import { ID, IPageAlert, INavLinkProps, IHeaderMenu, IFooterMenu } from '@belong/types';
import { usePlatformConfig } from '@belong/device-bridge';
import GlobalFooterContainer from 'src/components/containers/GlobalFooterContainer';
import GlobalHeaderContainer from 'src/components/containers/GlobalHeaderContainer';

export interface IProps {
  breadcrumbs: INavLinkProps[];
  children?: ReactNode;
  pageAlert?: IPageAlert;
  isLoggedIn: boolean;
  headerMenuContent: IHeaderMenu;
  footerMenuContent: IFooterMenu;
}

const DefaultLayout: FunctionComponent<IProps> = ({
  children,
  breadcrumbs,
  pageAlert,
  isLoggedIn,
  headerMenuContent,
  footerMenuContent
}: IProps) => {
  const { isNativeApp } = usePlatformConfig();
  if (!isNativeApp) {
    return (
      <GlobalHeaderContainer
        pageAlert={pageAlert}
        showBackToTopTrigger={false}
        headerMenuContent={headerMenuContent}
        isLoggedIn={isLoggedIn}
      >
        {breadcrumbs && <Breadcrumbs links={breadcrumbs} />}
        <main id={ID.CONTENT}>{children}</main>
        <GlobalFooterContainer {...footerMenuContent} />
      </GlobalHeaderContainer>
    );
  }
  return <>{children}</>;
};

export default DefaultLayout;
